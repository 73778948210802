import { StructUser } from "@/app/types/Thread.type";
import { STRUCT_API_VERSION } from "@/app/constants";
import { structAPIFetchClient } from "./client";
import { StructChannel } from "../types/Channel.type";

const MENTIONS_ENDPOINT = `/${STRUCT_API_VERSION}/mentions`;

export const getMentions = (
  query: string,
  hideDeleted = false,
  ids: string[] = [],
  limit = 5,
) => {
  let ENDPOINT = `${MENTIONS_ENDPOINT}?q=${query}&limit=${limit}`;
  if (hideDeleted) {
    ENDPOINT += "&bits=deleted,hidden,flip";
  }
  if (ids) {
    ENDPOINT += "&ids=" + ids.join(",");
  }
  return structAPIFetchClient(ENDPOINT, {
    method: "GET",
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then(
      ({
        users,
        channels,
      }: {
        users: StructUser[];
        channels: StructChannel[];
      }) => ({
        users: users || [],
        channels: channels || [],
      }),
    );
};
