import { StructUser } from "@/app/types/Thread.type";
import { StructChannel } from "@/app/types/Channel.type";
import { Node, NodeType } from "@/app/modules/slack-message-parser/types";
import { DocNode } from "@/app/store/ChatBox.store";

export const makeGetTipTapEditorStateFromSlackTree = (): ((
  node: Node,
) => DocNode) => {
  const getEditorStateFromSlackParser = (node: Node): any => {
    switch (node.type) {
      case NodeType.Root:
        return {
          type: "doc",
          content: [
            {
              type: "paragraph",
              content: node.children?.map(getEditorStateFromSlackParser),
            },
          ],
        };

      case NodeType.Text:
        return {
          type: "text",
          text: node.text,
        };

      case NodeType.ChannelMention:
        // todo: backfill this
        const channel = null; //channelsById[node.id];

        if (!channel) {
          return getEditorStateFromSlackParser({
            ...node,
            type: NodeType.Text,
            text: node.source,
          });
        }

        return {
          type: "mention",
          attrs: {
            // @ts-expect-error
            id: channel.id,
            // @ts-expect-error
            label: channel.name,
          },
        };

      case NodeType.UserMention:
        // todo: backfill this
        const user = null; //usersById[node.id];

        if (!user) {
          return getEditorStateFromSlackParser({
            ...node,
            type: NodeType.Text,
            text: node.source,
          });
        }

        return {
          type: "mention",
          attrs: {
            // @ts-expect-error
            id: user.id,
            // @ts-expect-error
            label: user.display_name,
          },
        };

      // case NodeType.URL:
      //   return {
      //     type: "text",
      //     marks: [
      //       {
      //         type: "link",
      //         attrs: {
      //           href: node.url,
      //           target: "_blank",
      //           rel: "noopener noreferrer nofollow",
      //           class: null,
      //         },
      //       },
      //     ],
      //     text: node.url,
      //   };

      case NodeType.Hashtag:
        return {
          type: "tag",
          attrs: {
            id: node.tag,
            label: node.tag,
          },
        };

      case NodeType.LineBreak:
        return {
          type: "hardBreak",
        };

      default:
        // @ts-ignore
        return { type: "text", text: node.source };
    }
  };

  return getEditorStateFromSlackParser;
};
