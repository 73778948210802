import { ReactNode } from "react";
import styles from "./BulletList.module.scss";

interface BulletListProps {
  children: ReactNode;
  isCompact?: boolean;
}

const BulletList = ({ children, isCompact }: BulletListProps) => (
  <ul className={isCompact ? styles.compactBulletList : styles.bulletList}>
    {children}
  </ul>
);

export default BulletList;
