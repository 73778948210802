import React, { Fragment } from "react";
import { v4 as uuidv4 } from "uuid";

import dynamic from "next/dynamic";
import { StructThread, StructUser } from "@/app/types/Thread.type";
import { StructChannel } from "@/app/types/Channel.type";
import { StructAuthenticatedUser } from "@/app/types/session";
import {
  Blockquote,
  Button,
  Code,
  Flex,
  Link,
  Text,
  Theme,
} from "@radix-ui/themes";
import CodeBlock from "@/app/components/Thread/ChatElements/CodeBlock";
import UserHoverCard from "@/app/components/Thread/ChatElements/UserHoverCard";
import MentionBadge from "@/app/components/Thread/ChatElements/MentionBadge";
import ChannelHoverCard from "@/app/components/Thread/ChatElements/ChannelHoverCard";
import TagHoverCard from "@/app/components/Thread/ChatElements/TagHoverCard";
import { StructTag } from "@/app/types/Tag.type";
import OrderedList from "@/app/components/Thread/ChatElements/OrderedList";
import BulletList from "@/app/components/Thread/ChatElements/BulletList";
import { BlockType, ChatBlock } from "@/app/types/Block.type";
import styles from "./ComponentsContainer.module.scss";
import {
  getReferenceDetails,
  getIsOnlyEmojis,
} from "@/app/utils/slackTreeConverters/utils";
import { CaretRightIcon } from "@radix-ui/react-icons";

const Emoji = dynamic(() =>
  import("@/app/components/Emoji").then((mod) => mod.Emoji),
);

const CustomEmoji = dynamic(() =>
  import("@/app/components/CustomEmoji").then((mod) => mod.CustomEmoji),
);

interface makeGetComponentsFromChatBlocksProps {
  authenticatedUserId?: StructAuthenticatedUser["id"];
  onlineUserIds: StructUser["id"][];
  onMessageUser: (
    id: StructUser["id"],
    name: StructUser["display_name"],
  ) => void;
  onViewChannel: (id: StructChannel["id"]) => void;
  onViewTag: (tag: StructTag["tag"]) => void;
  onOpenThread: (threadId: StructThread["id"]) => void;
}

export const makeGetComponentsFromChatBlocks = ({
  authenticatedUserId,
  onlineUserIds,
  onMessageUser,
  onViewChannel,
  onViewTag,
  onOpenThread,
}: makeGetComponentsFromChatBlocksProps): ((
  blocks: ChatBlock | ChatBlock[],
) => React.ReactNode) => {
  let isOnlyEmojis = false;
  const getComponentsFromSlackTree = (
    blocks: ChatBlock | ChatBlock[],
  ): React.ReactNode => {
    try {
      if (Array.isArray(blocks)) {
        isOnlyEmojis = getIsOnlyEmojis(blocks);
        return (
          <div
            className={styles.componentsContainer}
            style={{ whiteSpace: "pre-wrap" }}
          >
            {blocks.map((node) => getComponentsFromSlackTree(node))}
          </div>
        );
      }
      if (!blocks) console.trace("blocks");

      const id = uuidv4();
      if (!blocks) return null;

      switch (blocks.type) {
        case BlockType.RichText:
          return (
            <div key={id}>
              {blocks?.elements?.map((element) =>
                getComponentsFromSlackTree(element),
              )}
            </div>
          );

        case BlockType.RichTextSection:
          return (
            <div key={id}>
              {blocks?.elements?.map((element, index) => (
                <Fragment key={id + index}>
                  {getComponentsFromSlackTree(element)}
                </Fragment>
              ))}
            </div>
          );

        case BlockType.Text:
          if (blocks?.style?.code) {
            return <Code key={id}>{blocks.text}</Code>;
          }

          return (
            <Text
              weight={blocks.style?.bold ? "bold" : "regular"}
              key={id}
              size="2"
              style={{
                fontStyle: blocks.style?.italic ? "italic" : "normal",
                textDecoration: blocks.style?.strike ? "line-through" : "none",
                whiteSpace: "pre-wrap",
              }}
            >
              {blocks.text}
            </Text>
          );

        case BlockType.RichTextPreformatted:
          return <CodeBlock key={id} text={blocks?.elements[0]?.text!} />;

        case BlockType.Link:
          // TODO: implement in-app thread links
          return (
            <Link
              key={id}
              size="2"
              weight="medium"
              target="_blank"
              href={blocks.text}
            >
              {blocks.text}
            </Link>
          );

        case BlockType.RichTextQuote:
          return (
            <Blockquote key={id}>
              {blocks?.elements?.map((element) =>
                getComponentsFromSlackTree(element),
              )}
            </Blockquote>
          );

        case BlockType.Reference:
          const reference = getReferenceDetails(blocks.text!);

          if (reference.type === "user") {
            const user = (blocks?.entity as StructUser) ?? null;

            if (!user) {
              // TODO: render text
              // return <span>NO USER FOUND</span>;
              return <MentionBadge name={"DELETED"} variant="user" />;
            }

            const isAuthenticatedUser = user.id === authenticatedUserId;
            const isOnline = onlineUserIds.includes(user.id);

            return (
              <UserHoverCard
                key={id}
                displayName={user.display_name}
                realName={user.real_name}
                avatarId={user.avatar_id}
                isOnline={isOnline}
                onMessageUser={() => onMessageUser(user.id, user.display_name)}
                timeZone={user.time_zone}
                threadCount={user.num_threads}
              >
                <MentionBadge
                  name={user.display_name}
                  variant={isAuthenticatedUser ? "self" : "user"}
                />
              </UserHoverCard>
            );
          } else if (reference.type === "channel") {
            const channel = (blocks?.entity as StructChannel) ?? null;

            if (!channel) {
              // TODO: render text
              return <span>NO CHANNEL FOUND</span>;
            }

            return (
              <ChannelHoverCard
                key={id}
                name={channel?.name ?? ""}
                onViewChannel={() => onViewChannel(reference.id)}
                memberCount={channel?.num_users ?? 0}
                threadCount={channel?.num_threads ?? 0}
              />
            );
          } else if (reference.type === "tag") {
            return (
              <TagHoverCard
                key={id}
                id={id}
                name={reference.id}
                onViewTag={onViewTag}
              />
            );
          } else if (reference.type === "thread") {
            return (
              <Flex
                style={{
                  background: "var(--purple-a3)",
                  cursor: "pointer",
                  width: "max-content",
                  borderRadius: "var(--radius-2)",
                }}
                px="1"
                display="inline-flex"
                onClick={() => {
                  onOpenThread(reference.id);
                }}
                gap="1"
                align="center"
              >
                <Text size="1" mt="1" style={{ color: "var(--purple-a8)" }}>
                  @thread
                </Text>
                {blocks.elements && (
                  <Text
                    style={{
                      color: "var(--purple-a11)",
                      fontWeight: "var(--font-weight-medium)",
                    }}
                  >
                    {getComponentsFromSlackTree(blocks.elements)}
                  </Text>
                )}
                <CaretRightIcon />
              </Flex>
            );
          } else {
            return getComponentsFromSlackTree({
              type: BlockType.Text,
              text: `<${blocks.text}>`,
              elements: [],
            });
          }

        case BlockType.RichTextList:
          if (blocks.style?.ordered) {
            return (
              <OrderedList key={id}>
                {blocks?.elements?.map((element, index) => (
                  <li key={index}>{getComponentsFromSlackTree(element)}</li>
                ))}
              </OrderedList>
            );
          }

          return (
            <BulletList key={id}>
              {blocks?.elements?.map((element, index) => (
                <li key={index}>{getComponentsFromSlackTree(element)}</li>
              ))}
            </BulletList>
          );
        case BlockType.CustomEmoji:
          // No way to test
          return (
            <CustomEmoji
              style={{
                display: "inline-block",
                height: isOnlyEmojis
                  ? "var(--font-size-8)"
                  : "var(--font-size-4)",
                fontSize: isOnlyEmojis
                  ? "var(--font-size-8)"
                  : "var(--font-size-4)",
                lineHeight: isOnlyEmojis
                  ? "var(--line-height-8)"
                  : "var(--line-height-4)",
              }}
              key={blocks.name}
              unicode={blocks.name!}
            />
          );

        case BlockType.Emoji:
          return (
            <Emoji
              style={{
                display: "inline-block",
                height: isOnlyEmojis
                  ? "var(--font-size-8)"
                  : "var(--font-size-4)",
                fontSize: isOnlyEmojis
                  ? "var(--font-size-8)"
                  : "var(--font-size-4)",
                lineHeight: isOnlyEmojis
                  ? "var(--line-height-8)"
                  : "var(--line-height-4)",
              }}
              key={id}
              unicode={blocks.unicode!}
            />
          );

        default:
          return (
            <span
              style={{
                display: "inline-block",
              }}
              key={id}
            >
              <code>{JSON.stringify(blocks)}</code>
              <br />
            </span>
          );
      }
    } catch (error) {
      // console.error("error :", error);
    }
  };

  return getComponentsFromSlackTree;
};
