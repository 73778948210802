import React from "react";
import { IconType } from "@/app/components/icons/icon.type";

const ChannelGroupPeopleIcon = ({ style, className }: IconType) => (
  <svg
    style={style}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <rect width="16" height="16" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.22 9.1151C11.8 8.6951 11.24 8.4151 10.59 8.2551C11.42 7.8051 11.99 6.9451 11.99 5.93509C11.99 4.47509 10.8 3.2851 9.34 3.2851C7.88 3.2851 6.69 4.47509 6.69 5.93509C6.69 6.9451 7.26 7.8151 8.09 8.2551C7.43 8.4151 6.88 8.68509 6.46 9.1151C5.78 9.7951 5.43 10.8251 5.41 12.1751C5.41 12.4551 5.63 12.6751 5.9 12.6851C6.2 12.6651 6.4 12.4651 6.41 12.1951C6.42 11.1151 6.68 10.3251 7.17 9.8251C7.64 9.35509 8.37 9.1151 9.34 9.1151C10.31 9.1151 11.03 9.35509 11.51 9.8251C12 10.3151 12.26 11.1151 12.27 12.1951C12.27 12.4651 12.5 12.6851 12.77 12.6851C13.05 12.6851 13.27 12.4551 13.26 12.1751C13.24 10.8251 12.89 9.80509 12.21 9.1151H12.22ZM9.34 4.2851C10.25 4.2851 10.99 5.02509 10.99 5.93509C10.99 6.8451 10.25 7.5851 9.34 7.5851C8.43 7.5851 7.69 6.8451 7.69 5.93509C7.69 5.02509 8.43 4.2851 9.34 4.2851Z"
      fill="currentColor"
    />
    <path
      d="M6.44 3.36507C6.52 3.38507 6.59 3.41507 6.65 3.44507H6.64C6.78 3.52507 6.84 3.71507 6.78 3.91507C6.69 4.17507 6.47 4.38507 6.14 4.32507C6.07 4.30507 6.01 4.30507 5.93 4.30507C5.46 4.30507 5.01 4.58507 4.84 5.02507C4.56 5.78507 4.97 6.49507 5.62 6.67507C5.72 6.69507 5.79 6.78507 5.82 6.89507C5.88 7.08507 5.95 7.27507 6.03 7.46507L6.24 7.86507C6.31 7.98507 6.22 8.12507 6.08 8.12507H5.96C5.22 8.12507 4.66 8.30507 4.31 8.66507C3.94 9.04507 3.74 9.65508 3.74 10.4751C3.73 10.7551 3.51 10.9751 3.24 10.9751H3.23C2.95 10.9751 2.73 10.7451 2.74 10.4651C2.75 9.37507 3.04 8.52507 3.6 7.96507C3.89 7.66507 4.26 7.44507 4.69 7.30507C4.12 6.90507 3.75 6.24507 3.75 5.50507C3.75 4.28507 4.74 3.29507 5.96 3.29507C6.07 3.29507 6.18 3.30507 6.29 3.33507H6.35L6.44 3.36507Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.09 1.21509H1.91C0.85 1.21509 0 2.06509 0 3.12509V12.8751C0 13.9251 0.85 14.7851 1.91 14.7851H14.09C15.14 14.7851 16 13.9351 16 12.8751V3.12509C16 2.07509 15.15 1.21509 14.09 1.21509ZM14.09 2.21509C14.59 2.21509 15 2.62509 15 3.12509V12.8751C15 13.3751 14.59 13.7851 14.09 13.7851H1.91C1.41 13.7851 1 13.3751 1 12.8751V3.12509C1 2.62509 1.41 2.21509 1.91 2.21509H14.09Z"
      fill="currentColor"
    />
  </svg>
);

export default ChannelGroupPeopleIcon;
