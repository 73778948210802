// @ts-nocheck
import { explicit, oneOrMore, or, regexp, topOfLine } from "./combinator";
import { NodeType } from "@/app/modules/slack-message-parser/types";
const parseBold = explicit(
  regexp(
    /^\*(\S([^*\n]*?|[^*\n]*? `.*?` )[^\s*]|\S)\*(?=[\s~!#$%^)\-+={}[\];:'",.?/]|$)/,
    (match, _text, position, parseText) => {
      const [matchedText, content] = match;
      return [
        {
          type: NodeType.Bold,
          children: parseText(content),
          source: matchedText,
        },
        position + matchedText.length,
      ];
    },
  ),
);

const parseItalic = explicit(
  regexp(
    /^_(\S([^_\n]*?|[^_\n]*? `.*?` )\S|\S)\_(?=[\s.,\])}!?\-=]|$)/,
    (match, _text, position, parseText) => {
      const [matchedText, content] = match;
      return [
        {
          type: NodeType.Italic,
          children: parseText(content),
          source: matchedText,
        },
        position + matchedText.length,
      ];
    },
  ),
);
const parseStrike = explicit(
  regexp(
    /^~(\S([^~\n]*?|[^~\n]*? `.*?` )\S|\S)\~(?=[\s.,\])}!?\-=]|$)/,
    (match, _text, position, parseText) => {
      const [matchedText, content] = match;
      return [
        {
          type: NodeType.Strike,
          children: parseText(content),
          source: matchedText,
        },
        position + matchedText.length,
      ];
    },
  ),
);
const parseQuoteLine = topOfLine(
  regexp(
    /^(&gt;|>)(.*)(\n(&gt;|>)|\n|$)/,
    (match, _text, position, parseText) => {
      const [matchedText, gtSymbol, content] = match;
      const repeatedGt = content.match(/^((&gt;|>)+)(.*)$/);
      // Determine the correct gt symbol to use for replacement
      const gtToUse = gtSymbol === "&gt;" ? "&gt;" : ">";
      const source = matchedText.replace(new RegExp(`\\n${gtToUse}$`), "\n");
      const isNextLineStartsWithGt = source !== matchedText;
      return [
        {
          type: NodeType.Quote,
          children: repeatedGt
            ? [
                {
                  type: NodeType.Text,
                  text: repeatedGt[1],
                  source: repeatedGt[1],
                },
                ...parseText(repeatedGt[3]),
              ]
            : parseText(isNextLineStartsWithGt ? content + "\n" : content),
          source,
        },
        position + source.length,
      ];
    },
  ),
);

const parseQuote = or([
  topOfLine(
    regexp(/^&gt;&gt;&gt;([\s\S]+)$/, (match, _text, position, parseText) => {
      const [matchedText, content] = match;
      return [
        {
          type: NodeType.Quote,
          children: parseText(content),
          source: matchedText,
        },
        position + matchedText.length,
      ];
    }),
  ),
  oneOrMore(parseQuoteLine, (quotes) => {
    return {
      type: NodeType.Quote,
      children: quotes.map((quote) => quote.children).flat(),
      source: quotes.map((quote) => quote.source).join(""),
    };
  }),
]);
const parseCodeBlockWithLang = explicit(
  regexp(/^```(\w+)?\n([\s\S]+?)\n```/, (match, _text, position) => {
    const [matchedText, lang, code] = match;
    return [
      {
        type: NodeType.CodeBlock,
        text: code,
        language: lang || null,
        source: matchedText,
      },
      position + matchedText.length,
    ];
  }),
);

const parseInlineCode = explicit(
  regexp(/^`([^`]+?)`/, (match, _text, position) => {
    const [matchedText, code] = match;
    return [
      {
        type: NodeType.InlineCode,
        text: code,
        source: matchedText,
      },
      position + matchedText.length,
    ];
  }),
);

const parseLineBreak = regexp(/^(\n+)/, (match, _text, position) => {
  const [matchedText] = match;
  return [
    {
      type: NodeType.LineBreak,
      source: matchedText,
    },
    position + matchedText.length,
  ];
});

const parseBulletList = explicit(
  regexp(/^(\* .+(?:\n\* .+)*)/, (match, _text, position, parseText) => {
    const [matchedText] = match;
    const items = matchedText.split(/\n/).map((line) => {
      const content = line.substring(2); // remove "* "
      return {
        type: NodeType.BulletListItem,
        children: parseText(content),
        source: line,
      };
    });

    return [
      {
        type: NodeType.BulletList,
        children: items,
        source: matchedText,
      },
      position + matchedText.length,
    ];
  }),
);

const parseOrderedList = explicit(
  regexp(/^(\d+\.\s.*(?:\n\d+\.\s.*)*)/, (match, text, position, parseText) => {
    const [matchedText] = match;
    const potentialListItems = matchedText.split(/\n/);
    let actualListItems = [];
    let isStartOfLine = position === 0;

    for (const item of potentialListItems) {
      if (item.match(/^\d+\.\s/) && isStartOfLine) {
        actualListItems.push(item);
      } else {
        break;
      }
      isStartOfLine = true;
    }

    if (actualListItems.length === 0) {
      return null;
    }

    const items = actualListItems.map((line) => {
      const content = line.substring(line.indexOf(". ") + 2);
      return {
        type: NodeType.OrderedListItem,
        children: parseText(content),
        source: line,
      };
    });

    return [
      {
        type: NodeType.OrderedList,
        children: items,
        source: actualListItems.join("\n"),
      },
      position + actualListItems.join("\n").length,
    ];
  }),
);

const parseCombined = explicit(
  regexp(/^<([^<>]+)>/, (match, _text, position) => {
    const [matchedText, content] = match;
    const nextPosition = position + matchedText.length;

    // Check for a URL
    const urlRegex = /^(www\.|https?:\/\/)[\w.-]+/;
    if (urlRegex.test(content)) {
      return [
        {
          type: NodeType.URL,
          url: content,
          source: matchedText,
        },
        nextPosition,
      ];
    }

    // Check for a mention
    if (content.startsWith("@")) {
      const type = content.slice(1).startsWith("4")
        ? NodeType.UserMention
        : NodeType.ChannelMention;
      return [
        {
          type: type,
          id: content.slice(1),
          source: matchedText,
        },
        nextPosition,
      ];
    }

    // Check for a hashtag
    if (content.startsWith("#")) {
      return [
        {
          type: NodeType.Hashtag,
          tag: content.slice(1),
          source: matchedText,
        },
        nextPosition,
      ];
    }

    // If the content doesn't match any known pattern
    return [
      {
        type: NodeType.Unknown,
        content: content,
        source: matchedText,
      },
      nextPosition,
    ];
  }),
);

export default or([
  parseCombined,
  parseLineBreak,
  parseCodeBlockWithLang,
  parseInlineCode,
  parseBold,
  parseBulletList,
  parseOrderedList,
  parseItalic,
  parseQuote,
  parseStrike,
]);
