import { StructUser } from "@/app/types/Thread.type";
import { StructChannel } from "@/app/types/Channel.type";

export const replaceMentionedUserAndChannelIdsWithNames = (
  message: string,
  usersById: Record<StructUser["id"], StructUser>,
  channelsById: Record<StructChannel["id"], StructChannel>,
) => {
  if (!message) return message;
  return message.replace(/<?@([34][A-Za-f0-9]+)>?/g, (match, id) => {
    const prefix = id.charAt(0);
    if (prefix === "4") {
      return usersById[id] ? `@${usersById[id].display_name}` : match;
    }
    if (prefix === "3") {
      return channelsById[id] ? `@${channelsById[id].name}` : match;
    }
    return match;
  });
};

export const removeMentionsAndTags = (message: string) => {
  return message
    ?.replace(/(?:[^`"]|^)<@(3|4)\w*>|<#\w*\/?\w*>(?![`"])/g, "")
    .trim()
    .replace(/\s\s+/g, " ");
};

export const extractChannelIdsFromMessage = (message: string) => {
  const regex = /<@(3[^>]+)>/g;
  let match;
  const ids = [];

  while ((match = regex.exec(message)) !== null) {
    ids.push(match[1]);
  }

  return ids;
};

export const extractUserIdsFromMessage = (message: string) => {
  const regex = /<@(4[^>]+)>/g;
  let match;
  const ids = [];

  while ((match = regex.exec(message)) !== null) {
    ids.push(match[1]);
  }

  return ids;
};

export const extractTagsFromMessage = (message: string) => {
  const regex = /<#([^>]+)>/g;
  let match;
  const ids = [];

  while ((match = regex.exec(message)) !== null) {
    ids.push(match[1]);
  }

  return ids;
};

export const capitalize = (s: string): string => {
  if (s) {
    return s[0].toUpperCase() + s.slice(1);
  }
  return s;
};

export const processSearchQuery = (
  query: string,
): [string[], string[], string[], string] => {
  let channelIds: string[] = [],
    userIds: string[] = [],
    tags: string[] = [],
    searchQuery = "";
  try {
    channelIds = extractChannelIdsFromMessage(query);
    userIds = extractUserIdsFromMessage(query);
    tags = extractTagsFromMessage(query);
    searchQuery = removeMentionsAndTags(query);
  } catch (error) {
    console.error(error);
  }
  return [channelIds, userIds, tags, searchQuery];
};
