import React from "react";
import * as HoverCard from "@radix-ui/react-hover-card";
import { Avatar, Button, Flex, Text, Theme } from "@radix-ui/themes";
import { getAvatarImageURL } from "@/app/utils/imageUtils";
import {
  ChatBubbleIcon,
  ClockIcon,
  DotFilledIcon,
} from "@radix-ui/react-icons";
import { StructUser } from "@/app/types/Thread.type";
import { getCurrentTimeForUser } from "@/app/utils/timeUtils";

interface UserHoverCardProps {
  displayName: StructUser["display_name"];
  realName: StructUser["real_name"];
  avatarId: StructUser["avatar_id"];
  isOnline: boolean;
  onMessageUser: () => void;
  timeZone?: StructUser["time_zone"];
  threadCount: StructUser["num_threads"];
  children: React.ReactNode;
}

const UserHoverCard = ({
  displayName,
  realName,
  avatarId,
  isOnline,
  onMessageUser,
  timeZone,
  threadCount,
  children,
}: UserHoverCardProps) => {
  const handleMessageUser = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onMessageUser();
  };

  return (
    <HoverCard.Root openDelay={0} closeDelay={100}>
      <HoverCard.Trigger>{children}</HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content>
          <Theme accentColor="indigo" grayColor="slate" radius="large">
            <Flex
              p="4"
              gap="3"
              direction="column"
              style={{
                minWidth: 300,
                borderRadius: "var(--radius-4)",
                boxShadow: "var(--shadow-4)",
                backgroundColor: "var(--white-1)",
              }}
            >
              <Flex gap="4">
                <Flex direction="column" justify="center">
                  <Text size="4">{displayName}</Text>
                  <Text
                    size="1"
                    style={{
                      color: "var(--slate-11)",
                    }}
                  >
                    {realName}
                  </Text>
                </Flex>
                <Flex
                  justify="end"
                  style={{
                    flex: 1,
                  }}
                >
                  <Avatar
                    size="5"
                    src={getAvatarImageURL(avatarId)}
                    fallback={displayName?.[0]}
                  />
                </Flex>
              </Flex>
              <Flex direction="column" gap="4">
                <Flex
                  direction="column"
                  gap="1"
                  style={{
                    color: "var(--slate-11)",
                  }}
                >
                  <Flex gap="2" align="center">
                    <DotFilledIcon
                      style={{
                        color: isOnline ? "var(--indigo-9)" : "var(--slate-6)",
                      }}
                    />
                    <Text size="1">{isOnline ? "Online" : "Offline"}</Text>
                  </Flex>

                  {timeZone && (
                    <Flex gap="2" align="center">
                      <ClockIcon />{" "}
                      <Text size="1">
                        {timeZone} - {getCurrentTimeForUser(timeZone)}
                      </Text>
                    </Flex>
                  )}

                  <Flex gap="2" align="center">
                    <ChatBubbleIcon />
                    <Text size="1">{threadCount} threads</Text>
                  </Flex>
                </Flex>

                <Button variant="soft" onClick={handleMessageUser}>
                  Start a thread
                </Button>
              </Flex>
            </Flex>
          </Theme>
          <HoverCard.Arrow
            style={{
              fill: "var(--white-1)",
            }}
          />
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
};

export default UserHoverCard;
