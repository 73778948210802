import { StructChat, StructThread, StructUser } from "@/app/types/Thread.type";
import { StructChannel } from "@/app/types/Channel.type";
import { useAppStore } from "../store";
import { useMemo } from "react";
import useShallowNavigation from "./useShallowNavigation";
import { StructTag } from "@/app/types/Tag.type";
import slackMessageParser from "@/app/modules/slack-message-parser/mod";
import { makeGetComponentsFromChatBlocks } from "@/app/utils/slackTreeConverters/getComponentsFromChatBlocks";
import { makeGetTipTapEditorStateFromSlackTree } from "@/app/utils/slackTreeConverters/getEditorStateFromSlackParser";
import { makeGetCompactComponentsFromChatBlocks } from "@/app/utils/slackTreeConverters/getCompactComponentsFromChatBlocks";

export const useParser = () => {
  const sessionUserId = useAppStore((state) => state.session?.user?.id);
  const onlineUserIds = useAppStore((state) => state.onlineUserIds);
  const chatBoxCommands = useAppStore((state) => state.chatBoxCommands);

  const setSearchQuery = useAppStore((state) => state.setSearchQuery);

  const { navigateToPath } = useShallowNavigation();

  const handleMessageUser = (
    userId: StructUser["id"],
    userName: StructUser["display_name"],
  ) => {
    navigateToPath(null);
    chatBoxCommands?.clearContent();
    chatBoxCommands?.insertContent("Hi ");
    chatBoxCommands?.insertMention({
      id: userId,
      label: userName,
    });
    chatBoxCommands?.insertContent(", ");

    chatBoxCommands?.focus();
  };

  const handleViewChannel = (channelId: StructChannel["id"]) => {
    setSearchQuery(`<@${channelId}>`);
    navigateToPath(null);
  };

  const handleViewHashtag = (tag: StructTag["tag"]) => {
    setSearchQuery(`<#${tag}>`);
    navigateToPath(null);
  };

  const handleOpenThread = (threadId: StructThread["id"]) => {
    navigateToPath(threadId);
  };

  const getEditorState = useMemo(
    () => makeGetTipTapEditorStateFromSlackTree(),
    [],
  );

  const getComponentsFromChatBlocks = useMemo(
    () =>
      makeGetComponentsFromChatBlocks({
        authenticatedUserId: sessionUserId,
        onlineUserIds,
        onMessageUser: handleMessageUser,
        onViewChannel: handleViewChannel,
        onViewTag: handleViewHashtag,
        onOpenThread: handleOpenThread,
      }),
    [sessionUserId, onlineUserIds],
  );

  const getCompactComponentsFromChatBlocks = useMemo(
    () =>
      makeGetCompactComponentsFromChatBlocks({
        authenticatedUserId: sessionUserId,
      }),
    [sessionUserId],
  );

  return {
    handleMessageUser,
    handleViewHashtag,
    handleViewChannel,
    getCompactComponentsFromChatBlocks,
    getComponentsFromChatBlocks,
    getEditorStateFromChatText: (text: StructChat["text"]) =>
      getEditorState(slackMessageParser(text)),
  };
};
