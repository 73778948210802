import { EntityIDMap } from "@/app/constants";
import { BlockType, ChatBlock } from "@/app/types/Block.type";

const referenceTypeMap: Partial<{ [K in EntityIDMap]: string }> = {
  [EntityIDMap.User]: "user",
  [EntityIDMap.Channel]: "channel",
  [EntityIDMap.Thread]: "thread",
};

// TODO: this feels like a candidate for a global utility function
export const getReferenceType = (text: string): string | undefined => {
  if (text.startsWith("#")) {
    return "tag";
  }

  if (text.startsWith("@")) {
    const key = text[1] as EntityIDMap;
    return referenceTypeMap[key];
  }
};

export const getReferenceDetails = (text: string) => {
  const type = getReferenceType(text);
  const id = text.slice(1);

  return { type, id };
};

export const getIsOnlyEmojis = (node: ChatBlock[]): boolean => {
  if (node.length === 0) return false;

  if (node?.[0]?.type !== BlockType.RichText) {
    return false;
  }
  const chatBlock = node?.[0]?.elements?.[0];

  if (chatBlock?.type !== BlockType.RichTextSection) {
    return false;
  }

  const elements = chatBlock?.elements;

  const result = elements?.every((element) => element?.type === "emoji");

  if (result && elements.length > 9) {
    return false;
  }

  return result;
};
