import React, { useEffect, useRef } from "react";
import styles from "./MentionItem.module.scss";
import { Text, Flex, Badge, Separator } from "@radix-ui/themes";
import { PersonIcon } from "@radix-ui/react-icons";
import ChannelGroupPeopleIcon from "@/app/components/icons/ChannelGroupPeopleIcon";
import HashtagIcon from "@/app/components/icons/HashtagIcon";
import FeedPreviewSearchIcon from "./icons/FeedPreviewSearchIcon";

interface MentionItemProps extends React.ComponentPropsWithoutRef<"div"> {
  isActive: boolean;
  onMouseEnter: React.MouseEventHandler<HTMLDivElement>;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  type: "user" | "channel" | "tag" | "thread";
  count?: number | undefined;
  title: string;
  subTitle?: string;
  showCount?: boolean;
}

const typeIconMap = {
  user: PersonIcon,
  channel: ChannelGroupPeopleIcon,
  tag: HashtagIcon,
  thread: FeedPreviewSearchIcon,
};

const getFormattedCount = (count: number) => (count > 9999 ? "+9999" : count);

export const MentionItem = ({
  isActive,
  style,
  title,
  subTitle,
  type,
  count,
  onClick,
  onMouseEnter,
  showCount = true,
}: MentionItemProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isActive) {
      ref.current?.scrollIntoView({ block: "nearest" });
    }
  }, [isActive]);

  const Icon = typeIconMap[type];

  return (
    <div
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      ref={ref}
      className={styles.mentionItem}
      style={{
        backgroundColor: isActive ? "var(--slate-3)" : undefined,
        ...style,
      }}
    >
      <Flex justify="between" align="center" gap="2" width="100%">
        <Flex align="center" gap="4" style={{ minWidth: 0, flex: 1 }}>
          <Icon
            style={{
              color: type === "user" ? "var(--indigo-11)" : "var(--blue-11)",
              backgroundColor:
                type === "user" ? "var(--indigo-a3)" : "var(--blue-a3)",
              padding: "var(--space-1)",
              borderRadius: "var(--radius-2)",
            }}
          />
          <Flex
            style={{
              flex: 1,
              minWidth: 0,
            }}
            gap="2"
            align="center"
          >
            <Text weight="medium" size="2">
              {title}
            </Text>
            {subTitle && (
              <>
                <Separator orientation="vertical" size="1" />
                <Text
                  size="2"
                  style={{
                    color: "var(--slate-9)",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    flex: 1,
                    minWidth: 0,
                  }}
                >
                  {subTitle}
                </Text>
              </>
            )}
          </Flex>
        </Flex>
        {showCount && (
          <Badge color={type === "user" ? "indigo" : "blue"}>
            {getFormattedCount(count || 0)}
          </Badge>
        )}
      </Flex>
    </div>
  );
};
