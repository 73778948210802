import React from "react";
import { IconType } from "@/app/components/icons/icon.type";

const FeedPreviewSearchIcon = ({ style, className }: IconType) => (
  <svg
    style={style}
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" fill="white" fillOpacity="0.01" />
    <path
      d="M14.23 1H1.7C0.76 1 0 1.76 0 2.7V8.87C0 9.81 0.76 10.57 1.7 10.57H3.7C3.98 10.57 4.2 10.35 4.2 10.07C4.2 9.79 3.98 9.57 3.7 9.57H1.7C1.31 9.57 1 9.25 1 8.87V2.7C1 2.31 1.32 2 1.7 2H14.23C14.62 2 14.93 2.32 14.93 2.7V8.87C14.93 9.26 14.61 9.57 14.23 9.57C13.95 9.57 13.73 9.79 13.73 10.07C13.73 10.35 13.95 10.57 14.23 10.57C15.17 10.57 15.93 9.81 15.93 8.87V2.7C15.93 1.76 15.17 1 14.23 1Z"
      fill="currentColor"
    />
    <path
      d="M2.54999 4.82001H10.74C11.02 4.82001 11.24 4.60001 11.24 4.32001C11.24 4.04001 11.02 3.82001 10.74 3.82001H2.54999C2.26999 3.82001 2.04999 4.04001 2.04999 4.32001C2.04999 4.60001 2.26999 4.82001 2.54999 4.82001Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.92 9.49C12.92 10.41 12.59 11.25 12.06 11.93L14.49 14.36C14.69 14.56 14.69 14.87 14.49 15.07C14.4 15.17 14.26 15.22 14.14 15.22C14.02 15.22 13.89 15.17 13.79 15.07L11.36 12.64C10.69 13.16 9.83999 13.49 8.91999 13.49C6.70999 13.49 4.90999 11.69 4.90999 9.48C4.90999 8.52 5.25999 7.65 5.82999 6.96H2.54999C2.26999 6.96 2.04999 6.74 2.04999 6.46C2.04999 6.18 2.26999 5.96 2.54999 5.96H7.03999C7.59999 5.66 8.22999 5.48 8.90999 5.48C11.12 5.48 12.92 7.28 12.92 9.49ZM8.90999 6.48C7.24999 6.48 5.89999 7.83 5.89999 9.49C5.89999 11.15 7.24999 12.5 8.90999 12.5C10.57 12.5 11.92 11.15 11.92 9.49C11.92 7.83 10.57 6.48 8.90999 6.48Z"
      fill="currentColor"
    />
  </svg>
);

export default FeedPreviewSearchIcon;
