import { CodeIcon, FileIcon, Link2Icon } from "@radix-ui/react-icons";
import { Flex, Text } from "@radix-ui/themes";

interface FeedChatPillProps {
  variant: "link" | "code" | "quote" | "attachment";
  text?: string;
}

const variantMap = {
  link: {
    icon: Link2Icon,
    text: "Link",
  },
  code: {
    icon: CodeIcon,
    text: "Code snippet",
  },
  quote: {
    icon: null,
    text: "Quote",
  },
  attachment: {
    icon: FileIcon,
    text: "Attachment",
  },
};

const FeedChatPill = ({ variant, text }: FeedChatPillProps) => {
  const Icon = variantMap[variant].icon;

  return (
    <Flex
      gap="1"
      align="center"
      style={{
        whiteSpace: "nowrap",
        height: "1.25rem",
        backgroundColor: "var(--slate-a3)",
        color: "var(--slate-a11)",
        borderRadius: "var(--radius-2)",
        display: "inline-flex",
        padding: "0 var(--space-1)",
        borderLeft:
          variant === "quote" ? "3px solid var(--indigo-a7)" : undefined,
      }}
    >
      {Icon && (
        <Icon
          style={{
            color: "var(--slate-a9)",
          }}
        />
      )}
      <Text size="1">{Boolean(text) ? text : variantMap[variant].text}</Text>
    </Flex>
  );
};

export default FeedChatPill;
