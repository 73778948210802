import { Code, Flex, Text } from "@radix-ui/themes";
import React, { Fragment } from "react";
import MentionBadge from "@/app/components/Thread/ChatElements/MentionBadge";
import { v4 as uuidv4 } from "uuid";
import { StructUser } from "@/app/types/Thread.type";
import { StructChannel } from "@/app/types/Channel.type";
import dynamic from "next/dynamic";
import { StructAuthenticatedUser } from "@/app/types/session";
import FeedChatPill from "@/app/components/ChatComponents/FeedChatPill";
import { BlockType, ChatBlock } from "@/app/types/Block.type";
import OrderedList from "@/app/components/Thread/ChatElements/OrderedList";
import BulletList from "@/app/components/Thread/ChatElements/BulletList";
import { getReferenceDetails } from "@/app/utils/slackTreeConverters/utils";
import { CaretRightIcon } from "@radix-ui/react-icons";

const Emoji = dynamic(() =>
  import("@/app/components/Emoji").then((mod) => mod.Emoji),
);
const CustomEmoji = dynamic(() =>
  import("@/app/components/CustomEmoji").then((mod) => mod.CustomEmoji),
);

interface makeGetCompactComponentsFromChatBlocksProps {
  authenticatedUserId?: StructAuthenticatedUser["id"];
}

export const makeGetCompactComponentsFromChatBlocks = ({
  authenticatedUserId,
}: makeGetCompactComponentsFromChatBlocksProps): ((
  blocks: ChatBlock | ChatBlock[],
) => React.ReactNode) => {
  const getCompactComponentsFromChatBlocks = (
    blocks: ChatBlock | ChatBlock[],
  ): React.ReactNode => {
    const id = uuidv4();
    try {
      if (Array.isArray(blocks)) {
        return (
          <Flex
            gap="1"
            align="center"
            style={{ whiteSpace: "nowrap" }}
            key={id}
          >
            {blocks.map((node) => getCompactComponentsFromChatBlocks(node))}
          </Flex>
        );
      }

      if (!blocks) return null;

      switch (blocks.type) {
        case BlockType.RichText:
          return blocks?.elements?.map((element, index) => (
            <Fragment key={id + index}>
              {getCompactComponentsFromChatBlocks(element)}
            </Fragment>
          ));

        case BlockType.RichTextSection:
          return (
            <>
              {blocks?.elements?.map((element, index) => (
                <Fragment key={id + index}>
                  {getCompactComponentsFromChatBlocks(element)}
                </Fragment>
              ))}
            </>
          );

        case BlockType.Text:
          if (blocks?.style?.code) {
            return (
              <Code
                style={{
                  whiteSpace: "nowrap",
                }}
                key={id}
              >
                {blocks.text}
              </Code>
            );
          }

          return (
            <Text
              weight={blocks.style?.bold ? "bold" : "regular"}
              key={id}
              size="2"
              style={{
                fontStyle: blocks.style?.italic ? "italic" : "normal",
                textDecoration: blocks.style?.strike ? "line-through" : "none",
                whiteSpace: "nowrap",
              }}
            >
              {blocks.text}
            </Text>
          );

        case BlockType.RichTextPreformatted:
          return <FeedChatPill key={id} variant="code" />;

        case BlockType.Link:
          // TODO: implement in-app thread links
          return <FeedChatPill key={id} variant="link" />;

        case BlockType.RichTextQuote:
          const text =
            blocks?.elements?.map((element) => element.text).join("") ?? "";

          return <FeedChatPill key={id} variant="quote" text={text} />;

        case BlockType.Reference:
          const reference = getReferenceDetails(blocks.text!);

          if (reference.type === "user") {
            const user = (blocks.entity as StructUser) ?? {};

            if (!user) {
              return <MentionBadge key={id} name="DELETED" variant="user" />;
            }

            const isAuthenticatedUser = user.id === authenticatedUserId;
            return (
              <MentionBadge
                key={user.id + id}
                name={user.display_name}
                variant={isAuthenticatedUser ? "self" : "user"}
              />
            );
          } else if (reference.type === "channel") {
            const channel = (blocks.entity as StructChannel) ?? {};

            if (!channel) {
              return <MentionBadge key={id} name="DELETED" variant="user" />;
            }

            return (
              <MentionBadge
                key={channel.id + id}
                name={channel.name}
                variant="channel"
                isHoverable={false}
              />
            );
          } else if (reference.type === "tag") {
            return (
              <MentionBadge
                key={id}
                name={reference.id}
                variant="hashtag"
                isHoverable={false}
              />
            );
          } else if (reference.type === "thread") {
            return (
              <Flex
                style={{
                  background: "var(--purple-a3)",
                  cursor: "pointer",
                  width: "max-content",
                  borderRadius: "var(--radius-2)",
                }}
                px="1"
                display="inline-flex"
                gap="1"
                align="center"
                key={id}
              >
                <Text size="1" style={{ color: "var(--purple-a8)" }}>
                  @thread
                </Text>
                {blocks.elements && (
                  <Text
                    style={{
                      color: "var(--purple-a11)",
                      fontWeight: "var(--font-weight-medium)",
                    }}
                  >
                    {getCompactComponentsFromChatBlocks(blocks.elements)}
                  </Text>
                )}
                <CaretRightIcon />
              </Flex>
            );
          }

        case BlockType.RichTextList:
          if (blocks.style?.ordered) {
            return (
              <OrderedList key={id} isCompact>
                {blocks?.elements?.map((element, index) => (
                  <li key={id + index}>
                    {getCompactComponentsFromChatBlocks(element)}
                  </li>
                ))}
              </OrderedList>
            );
          }

          return (
            <BulletList key={id} isCompact>
              {blocks?.elements?.map((element, index) => (
                <li key={id + index}>
                  {getCompactComponentsFromChatBlocks(element)}
                </li>
              ))}
            </BulletList>
          );

        case BlockType.Emoji:
          return <Emoji key={id} unicode={blocks.unicode!} />;

        case BlockType.CustomEmoji:
          // No way to test
          return <CustomEmoji key={id} unicode={blocks.unicode!} />;

        default:
          return (
            <span
              style={{
                display: "inline-block",
              }}
              key={id}
            >
              <code>{JSON.stringify(blocks)}</code>
              <br />
            </span>
          );
      }
    } catch (error) {
      console.error("error :", error);
    }
  };

  return getCompactComponentsFromChatBlocks;
};
