import { StructUser } from "@/app/types/Thread.type";
import Fuse from "fuse.js";
import { StructOrganisation } from "@/app/types/Organisation.type";

export const getAnonymousUser = (
  organisation?: StructOrganisation,
): StructUser => ({
  id: "none",
  xid: "none",
  display_name: "Anonymous",
  real_name: "Anonymous",
  email_id: "no email",
  avatar_id: "none",
  anon: "Anonymous",
  created_at: 0,
  updated_at: 0,
  org_id: organisation ? organisation.id : "none",
  channel_ids: [],
  bits: {
    user_admin: false,
    user_owner: false,
  },
});

const fuseOptions = {
  keys: ["real_name", "display_name"],
  ignoreLocation: true,
  threshold: 0.1,
};

export const getFuseUsersInstance = (users: StructUser[]) => {
  const fuseUsersInstance = new Fuse(users, fuseOptions);
  return fuseUsersInstance;
};

export const getFirstUsers = (
  usersById: Record<StructUser["id"], StructUser>,
  numberOfUsers: number,
): StructUser[] => {
  const result: StructUser[] = [];
  let count = 0;

  for (let key in usersById) {
    if (usersById.hasOwnProperty(key)) {
      result.push(usersById[key]);
      count++;
      if (count === numberOfUsers) break;
    }
  }

  return result;
};
