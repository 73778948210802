import React, { ReactNode } from "react";
import * as HoverCard from "@radix-ui/react-hover-card";
import MentionBadge from "@/app/components/Thread/ChatElements/MentionBadge";
import { Button, Flex, Text, Theme } from "@radix-ui/themes";
import ChannelGroupPeopleIcon from "@/app/components/icons/ChannelGroupPeopleIcon";
import PeopleIcon from "@/app/components/icons/PeopleIcon";
import { ChatBubbleIcon } from "@radix-ui/react-icons";
import { StructChannel } from "@/app/types/Channel.type";

interface ChannelHoverCardProps {
  name: StructChannel["name"];
  onViewChannel: () => void;
  memberCount: number | undefined;
  threadCount: number;
  child?: ReactNode;
}

const ChannelHoverCard = ({
  name,
  onViewChannel,
  memberCount,
  threadCount,
  child,
}: ChannelHoverCardProps) => {
  return (
    <HoverCard.Root openDelay={0} closeDelay={0}>
      <HoverCard.Trigger>
        {child ? child : <MentionBadge name={name} variant="channel" />}
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content>
          <Theme accentColor="indigo" grayColor="slate" radius="large">
            <Flex
              p="4"
              gap="4"
              direction="column"
              style={{
                minWidth: 300,
                borderRadius: "var(--radius-4)",
                boxShadow: "var(--shadow-4)",
                backgroundColor: "var(--white-1)",
              }}
            >
              <Flex justify="between" align="center">
                <Text size="4">@{name}</Text>
                <Flex
                  align="center"
                  justify="center"
                  style={{
                    height: "var(--space-7)",
                    width: "var(--space-7)",
                    borderRadius: "var(--radius-4)",
                    background: "var(--indigo-a3)",
                  }}
                >
                  <ChannelGroupPeopleIcon
                    style={{
                      color: "var(--indigo-11)",
                    }}
                  />
                </Flex>
              </Flex>

              <Flex
                direction="column"
                gap="1"
                style={{
                  color: "var(--slate-11)",
                }}
              >
                <Flex gap="2" align="center">
                  <PeopleIcon />
                  <Text size="1">{memberCount ? memberCount : 0}</Text>
                </Flex>

                <Flex gap="2" align="center">
                  <ChatBubbleIcon />
                  <Text size="1">{threadCount} threads</Text>
                </Flex>
              </Flex>

              <Flex direction="column" gap="1">
                <Button variant="soft" onClick={onViewChannel}>
                  View threads
                </Button>
              </Flex>
            </Flex>
          </Theme>

          <HoverCard.Arrow
            style={{
              fill: "var(--white-1)",
            }}
          />
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
};

export default ChannelHoverCard;
