import React, { forwardRef, Ref } from "react";
import { Flex, Text } from "@radix-ui/themes";

// TODO: consider using this in the whole app

interface MentionBadgeProps {
  name: string;
  variant: "channel" | "user" | "thread" | "hashtag" | "self";
  isHoverable?: boolean;
}

const backgroundColorMap = {
  channel: "var(--blue-a3)",
  user: "var(--indigo-a3)",
  self: "var(--yellow-a3)",
  thread: "var(--purple-a3)",
  hashtag: "var(--cyan-a3)",
};

const textColorMap = {
  channel: "var(--blue-a11)",
  user: "var(--indigo-a11)",
  self: "var(--indigo-a11)",
  thread: "var(--purple-a11)",
  hashtag: "var(--cyan-a11)",
};

const prefixMap = {
  channel: "@",
  user: "@",
  self: "@",
  thread: "@thread ", //TODO: style this nicely
  hashtag: "#",
};

const MentionBadge = forwardRef(
  (
    { name, variant, isHoverable = true, ...other }: MentionBadgeProps,
    ref: Ref<HTMLDivElement>,
  ) => (
    <Flex
      {...other}
      ref={ref}
      align="center"
      display="inline-flex"
      style={{
        backgroundColor: backgroundColorMap[variant],
        borderRadius: "var(--radius-2)",
        height: "1.25rem",
        padding: "0 var(--space-1)",
        cursor: isHoverable ? "default" : "inherit",
      }}
    >
      <Text
        weight="medium"
        size="2"
        style={{
          color: textColorMap[variant],
          width: "max-content",
        }}
      >
        {prefixMap[variant]}
        {name}
      </Text>
    </Flex>
  ),
);

MentionBadge.displayName = "MentionBadge";

export default MentionBadge;
