import { Button, Flex, Text, Theme } from "@radix-ui/themes";
import * as HoverCard from "@radix-ui/react-hover-card";
import React from "react";
import MentionBadge from "./MentionBadge";
import HashtagIcon from "../../icons/HashtagIcon";

interface TagHoverCardProps {
  id: string;
  name: string;
  onViewTag: (name: string) => void;
  children?: React.ReactNode;
}

const TagHoverCard = ({ id, name, onViewTag, children }: TagHoverCardProps) => {
  return (
    <HoverCard.Root openDelay={0} closeDelay={100} key={id}>
      <HoverCard.Trigger>
        {children ? (
          children
        ) : (
          <MentionBadge key={id} name={name} variant="hashtag" />
        )}
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content>
          <Theme accentColor="indigo" grayColor="slate" radius="large">
            <Flex
              p="4"
              gap="4"
              direction="column"
              style={{
                minWidth: 300,
                borderRadius: "var(--radius-4)",
                boxShadow: "var(--shadow-4)",
                backgroundColor: "var(--white-1)",
              }}
            >
              <Flex justify="between" align="center">
                <Text size="4">#{name}</Text>
                <Flex
                  align="center"
                  justify="center"
                  style={{
                    height: "var(--space-7)",
                    width: "var(--space-7)",
                    borderRadius: "var(--radius-4)",
                    background: "var(--cyan-a3)",
                  }}
                >
                  <HashtagIcon
                    style={{
                      color: "var(--cyan-11)",
                    }}
                  />
                </Flex>
              </Flex>
              <Flex direction="column">
                <Button variant="soft" onClick={() => onViewTag(name)}>
                  View threads
                </Button>
              </Flex>
            </Flex>
          </Theme>

          <HoverCard.Arrow
            style={{
              fill: "var(--white-1)",
            }}
          />
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
};

export default TagHoverCard;
