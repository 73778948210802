import { useEffect, useState } from "react";

const getCurrentPathSegment = () => window.location.pathname.slice(1);

const useShallowNavigation = () => {
  const [currentPath, setCurrentPath] = useState<string | null>("");

  useEffect(() => {
    const handlePopState = () => {
      setCurrentPath(getCurrentPathSegment());
    };

    handlePopState();

    window.addEventListener("popstate", handlePopState);
    window.addEventListener("pathChanged", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
      window.removeEventListener("pathChanged", handlePopState);
    };
  }, []);

  const navigateToPath = (newPath: string | null) => {
    getCurrentPath().then((oldPath) => {
      const oldURL = oldPath ? `/${oldPath}` : "/";
      const updatedURL = newPath ? `/${newPath}` : "/";
      window.history.pushState({}, "", oldURL);
      window.history.replaceState({}, "", updatedURL);
      window.dispatchEvent(new Event("pathChanged"));
    });
  };

  const getCurrentPath = (): Promise<string | null> => {
    // This sorcery helps us to get the current path in a synchronous and deterministic way
    return new Promise((resolve) => {
      setCurrentPath((currentPath) => {
        resolve(currentPath);
        return currentPath;
      });
    });
  };

  return { currentPath, navigateToPath, getCurrentPath };
};

export default useShallowNavigation;
