import { IconType } from "@/app/components/icons/icon.type";

const PeopleIcon = ({ style, className }: IconType) => (
  <svg
    style={style}
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Objects / person-people">
      <rect width="16" height="16" fill="transparent" />
      <g id="Vector">
        <path
          d="M5.29 8.33001C5.63 8.31001 5.75 8.06001 5.54 7.76001L5.38 7.53001C5.16 7.21001 5.09 7.10001 4.98 6.96001C4.92 6.81001 4.78 6.70001 4.63 6.64001C3.93 6.37001 3.43 5.68001 3.43 4.88001C3.43 3.85001 4.27 3.01001 5.3 3.01001C5.34 3.01001 5.38 3.01001 5.41 3.02001C5.5 3.02001 5.59 3.03001 5.68 3.04001C5.96 3.10001 6.22 2.92001 6.27 2.65001C6.32 2.44001 6.22 2.24001 6.05 2.14001C6 2.10001 5.94 2.07001 5.88 2.06001C5.54 2.01001 5.19 1.98001 4.79 2.06001C3.66 2.25001 2.72 3.13001 2.49 4.24001C2.19 5.64001 2.91 6.92001 4.04 7.46001C3.27 7.61001 2.63 7.94001 2.15 8.43001C1.4 9.18001 1.02 10.31 1 11.78C1 12.06 1.22 12.28 1.5 12.29C1.78 12.29 2 12.07 2 11.79C2.02 10.59 2.3 9.69001 2.86 9.14001C3.39 8.60001 4.21 8.33001 5.29 8.33001Z"
          fill="currentColor"
        />
        <path
          d="M10.82 8.78001C12.2 8.32001 13.21 7.02001 13.21 5.49001C13.21 3.57001 11.65 2.01001 9.72 2.01001C7.79 2.01001 6.23 3.57001 6.23 5.49001C6.23 7.03001 7.24 8.32001 8.62 8.78001C7.47 8.94001 6.54 9.36001 5.86 10.05C4.95 10.97 4.48 12.35 4.46 14.17C4.46 14.45 4.68 14.67 4.95 14.68C5.22 14.68 5.45 14.46 5.45 14.19C5.47 12.64 5.84 11.49 6.56 10.77C7.25 10.07 8.31 9.72001 9.71 9.72001C11.11 9.72001 12.17 10.07 12.86 10.77C13.58 11.5 13.95 12.65 13.97 14.2C13.97 14.47 14.2 14.69 14.47 14.69C14.75 14.69 14.97 14.46 14.96 14.18C14.94 12.36 14.47 10.98 13.56 10.06C12.88 9.37001 11.95 8.95001 10.8 8.79001L10.82 8.78001ZM7.24 5.49001C7.24 4.12001 8.36 3.01001 9.73 3.01001C11.1 3.01001 12.22 4.12001 12.22 5.49001C12.22 6.86001 11.1 7.98001 9.73 7.98001C8.36 7.98001 7.24 6.86001 7.24 5.49001Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default PeopleIcon;
