import { useMemo } from "react";
import { Button, Flex } from "@radix-ui/themes";
import { CopyIcon } from "@radix-ui/react-icons";
import SyntaxHighlighter, {
  Prism as PrismHighlighter,
} from "react-syntax-highlighter";
import { nightOwl as nightOwlHLJS } from "react-syntax-highlighter/dist/cjs/styles/hljs";
import { nightOwl as nightOwlPrism } from "react-syntax-highlighter/dist/cjs/styles/prism";
import styles from "./CodeBlock.module.scss";

const HIGHLIGHTER_BREAKPOINT = 1;

interface CodeBlockProps {
  text: string;
}

// TODO: add support for fetching language from the first line
const CodeBlock = ({ text }: CodeBlockProps) => {
  const memoizedHighlighter = useMemo(() => {
    const textLength = text?.length ?? 0;
    const Highlighter =
      textLength > HIGHLIGHTER_BREAKPOINT
        ? PrismHighlighter
        : SyntaxHighlighter;
    const styles =
      textLength > HIGHLIGHTER_BREAKPOINT ? nightOwlPrism : nightOwlHLJS;
    return (
      <Highlighter
        className={styles.codeBlock}
        // showLineNumbers
        wrapLongLines
        wrapLines
        // language={node.language}
        style={{ ...styles }}
        customStyle={{
          fontSize: "var(--font-size-2)",
          lineHeight: "var(--line-height-2)",
          margin: 0,
          overflowX: "hidden",
        }}
      >
        {text}
      </Highlighter>
    );
  }, [text]);

  return (
    <Flex className={styles.codeBlockContainer} direction="column">
      <Flex className={styles.header} justify="end">
        <Button
          size="1"
          variant="soft"
          color="gray"
          onClick={() => {
            navigator.clipboard.writeText(text);
          }}
        >
          <CopyIcon /> Copy code
        </Button>
      </Flex>
      {memoizedHighlighter}
    </Flex>
  );
};
export default CodeBlock;
