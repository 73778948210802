import { StructChannel } from "./Channel.type";
import { StructUser } from "./Thread.type";

export enum BlockType {
  RichText = "rich_text",
  RichTextSection = "rich_text_section",
  RichTextQuote = "rich_text_quote",
  RichTextList = "rich_text_list",
  RichTextPreformatted = "rich_text_preformatted",
  Text = "text",
  Link = "link",
  Reference = "ref",
  Emoji = "emoji",
  CustomEmoji = "emoji_custom",
}

export interface ChatBlock {
  type: string;
  elements: ChatBlock[];
  style?: {
    italic?: boolean;
    bold?: boolean;
    strike?: boolean;
    code?: boolean;
    ordered?: boolean;
  };
  text?: string;
  name?: string;
  unicode?: string;
  entity?: StructUser | StructChannel;
}
