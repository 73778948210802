import React from "react";
import { IconType } from "@/app/components/icons/icon.type";

const HashtagIcon = ({ style, className }: IconType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    style={style}
    className={className}
  >
    <rect width="16" height="16" fill="white" fillOpacity="0" />
    <path
      d="M14.1199 6.54253C14.3999 6.54253 14.6199 6.32253 14.6199 6.04253C14.6199 5.76253 14.3999 5.54253 14.1199 5.54253H10.7699L11.1799 2.18253C11.2099 1.91253 11.0199 1.66253 10.7399 1.62253C10.4699 1.58253 10.2199 1.78253 10.1799 2.06253L9.74988 5.54253H6.83988L7.24988 2.18253C7.27988 1.91253 7.08988 1.66253 6.80988 1.62253C6.54988 1.58253 6.28988 1.78253 6.24988 2.06253L5.81988 5.54253H2.35988C2.07988 5.54253 1.85988 5.76253 1.85988 6.04253C1.85988 6.32253 2.07988 6.54253 2.35988 6.54253H5.70988L5.34988 9.46253H1.87988C1.59988 9.46253 1.37988 9.68253 1.37988 9.96253C1.37988 10.2425 1.59988 10.4625 1.87988 10.4625H5.22988L4.81988 13.8225C4.78988 14.0925 4.97988 14.3425 5.25988 14.3825C5.27988 14.3825 5.29988 14.3825 5.31988 14.3825C5.56988 14.3825 5.77988 14.1925 5.81988 13.9425L6.24988 10.4625H9.15988L8.74988 13.8225C8.71988 14.0925 8.90988 14.3425 9.18988 14.3825C9.20988 14.3825 9.22988 14.3825 9.24988 14.3825C9.49988 14.3825 9.70988 14.1925 9.74988 13.9425L10.1799 10.4625H13.6599C13.9399 10.4625 14.1599 10.2425 14.1599 9.96253C14.1599 9.68253 13.9399 9.46253 13.6599 9.46253H10.3099L10.6699 6.54253H14.1499H14.1199ZM9.27988 9.46253H6.36988L6.72988 6.54253H9.63988L9.27988 9.46253Z"
      fill="currentColor"
    />
  </svg>
);

export default HashtagIcon;
