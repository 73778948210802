import styles from "./OrderedList.module.scss";
import { ReactNode } from "react";
interface OrderedListProps {
  children: ReactNode;
  isCompact?: boolean;
}

const OrderedList = ({ children, isCompact }: OrderedListProps) => (
  <ol className={isCompact ? styles.compactOrderedList : styles.orderedList}>
    {children}
  </ol>
);

export default OrderedList;
