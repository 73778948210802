import { SuggestionKeyDownProps, SuggestionProps } from "@tiptap/suggestion";
import {
  forwardRef,
  useImperativeHandle,
  useState,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { MentionItem } from "./MentionItem";
import styles from "./MentionList.module.scss";
import { Flex, Separator, Text, Theme } from "@radix-ui/themes";
import { VirtualElement } from "@popperjs/core";
import { StructUser } from "@/app/types/Thread.type";
import { StructChannel } from "@/app/types/Channel.type";
import { StructTag } from "@/app/types/Tag.type";
import { useAppStore } from "../store";
import events from "../utils/events";
import { EMBED_THREAD_FROM_CMDK } from "../eventConstants";

interface MentionListProps extends SuggestionProps {
  type: "mention" | "tag" | "thread";
  canCreateTags?: boolean;
  showSearch?: boolean;
  isChatBoxEditor?: boolean;
}

interface MentionListActions {
  onKeyDown: (props: SuggestionKeyDownProps) => void;
}

export const MentionList = forwardRef<MentionListActions, MentionListProps>(
  (
    {
      clientRect,
      command,
      items,
      query,
      type,
      canCreateTags = true,
      showSearch = false,
      editor,
      range,
      isChatBoxEditor = true,
    },
    ref,
  ) => {
    const searchThreadIndex = items.length;
    const isCommandMenuVisible = useAppStore(
      (state) => state.isCommandMenuVisible,
    );

    const setIsCommandMenuVisible = useAppStore(
      (state) => state.setIsCommandMenuVisible,
    );

    const rangeRef = useRef(range);

    const referenceEl = useMemo(() => {
      if (!clientRect) return null;
      return {
        getBoundingClientRect: () => {
          const rect = clientRect();
          return rect
            ? rect
            : ({
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                width: 0,
                height: 0,
              } as DOMRect);
        },
      } as VirtualElement;
    }, [clientRect]);

    const handleCommand = (index: number) => {
      const selectedItem = items[index];
      if (!selectedItem) return;

      if (type === "tag") {
        command({
          id: selectedItem.tag,
          label: selectedItem.tag,
          type: selectedItem.type,
        });
        return;
      }
      command({
        id: selectedItem.id,
        label: selectedItem.display_name ?? selectedItem.name,
        type: selectedItem.type,
      });
    };

    const handleCreateTagCommand = () => {
      command({
        id: query,
        label: query,
        type: "tag",
      });
    };

    const handleEmbedThread = ({
      id,
      label,
    }: {
      id: string;
      label: string;
    }) => {
      editor.commands.deleteRange(rangeRef.current);
      editor.commands.focus();
      command({
        id,
        label,
        type: "thread",
      });
    };

    useEffect(() => {
      events.on(EMBED_THREAD_FROM_CMDK, handleEmbedThread);
      return () => {
        events.off(EMBED_THREAD_FROM_CMDK, handleEmbedThread);
      };
    }, []);

    const [hoverIndex, setHoverIndex] = useState(0);

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event, view }) => {
        const { key } = event;
        if (["ArrowUp", "ArrowDown", "Enter", "Tab"].includes(key)) {
          event.preventDefault();
          event.stopPropagation();
        }

        if (key === "ArrowUp") {
          setHoverIndex((prev) => Math.max(prev - 1, 0));
          return true;
        }

        if (key === "ArrowDown") {
          setHoverIndex((prev) =>
            Math.min(prev + 1, showSearch ? items.length : items.length - 1),
          );
          return true;
        }

        if (key === "Enter" && hoverIndex === searchThreadIndex && showSearch) {
          // setShowSearchPanel(true);
          setIsCommandMenuVisible(true, true);
          rangeRef.current = range;
          return true;
        }

        if (key === "Tab") {
          handleCommand(hoverIndex);
          return true;
        }

        if (key !== "Enter") {
          return false;
        }

        if (type === "tag" && tags.length === 0) {
          handleCreateTagCommand();
          return true;
        }

        handleCommand(hoverIndex);
        return true;
      },
    }));

    const [el, setEl] = useState<HTMLDivElement | null>(null);
    const { styles: popperStyles, attributes } = usePopper(referenceEl, el, {
      placement: "top-start",
    });

    const users: StructUser[] = items.filter((item) => item.type === "user");
    const channels: StructChannel[] = items.filter(
      (item) => item.type === "channel",
    );
    const tags: StructTag[] = items.filter((item) => item.type === "tag");

    const calcIndex = (isChannel: boolean, index: number) =>
      isChannel ? index : channels.length + index;

    if (query === "" && items.length === 0) {
      return null;
    }

    return createPortal(
      <Theme accentColor="indigo" grayColor="slate" radius="large">
        <div
          ref={setEl}
          className={styles.mentionList}
          style={{
            ...popperStyles.popper,
            ...(isCommandMenuVisible && isChatBoxEditor
              ? { visibility: "hidden" }
              : { visibility: "visible" }),
          }}
          {...attributes.popper}
        >
          <Flex
            grow="1"
            direction="column"
            style={{ background: "var(--white-1)" }}
          >
            {tags.map((tag, index) => (
              <MentionItem
                key={tag.tag}
                isActive={calcIndex(true, index) === hoverIndex}
                onMouseEnter={() => setHoverIndex(calcIndex(true, index))}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  event.nativeEvent.stopImmediatePropagation();
                  handleCommand(calcIndex(true, index));
                }}
                type="tag"
                title={tag.tag}
                count={tag.count}
              />
            ))}
            {channels.map((channel, index) => (
              <MentionItem
                key={channel.id}
                isActive={calcIndex(true, index) === hoverIndex}
                onMouseEnter={() => setHoverIndex(calcIndex(true, index))}
                onClick={() => {
                  handleCommand(calcIndex(true, index));
                }}
                type="channel"
                count={channel.num_threads}
                title={channel.name}
              />
            ))}
            {channels.length > 0 && users.length > 0 && (
              <Separator orientation="horizontal" size="4" my="2" />
            )}
            {users.map((user, index) => (
              <MentionItem
                key={user.id}
                isActive={calcIndex(false, index) === hoverIndex}
                onMouseEnter={() => setHoverIndex(calcIndex(false, index))}
                onClick={() => handleCommand(calcIndex(false, index))}
                type="user"
                count={user.num_threads}
                title={user.display_name}
                subTitle={user.real_name}
              />
            ))}
            {type === "mention" &&
              channels.length === 0 &&
              users.length === 0 && (
                <Text
                  style={{
                    color: "var(--slate-7)",
                    padding: "var(--space-1)",
                  }}
                >
                  No channel or member results
                </Text>
              )}

            {type === "tag" &&
              tags.length === 0 &&
              query !== "" &&
              canCreateTags && (
                <MentionItem
                  key={"unique"}
                  isActive={calcIndex(true, 0) === hoverIndex}
                  onMouseEnter={() => setHoverIndex(calcIndex(true, 0))}
                  onClick={() => {
                    handleCreateTagCommand();
                  }}
                  type="tag"
                  title="Create a new tag"
                  showCount={false}
                />
              )}
            {showSearch && type === "mention" && (
              <MentionItem
                key={"unique"}
                isActive={searchThreadIndex === hoverIndex}
                onMouseEnter={() => setHoverIndex(searchThreadIndex)}
                onClick={(event) => {
                  // setShowSearchPanel(true);

                  setIsCommandMenuVisible(true, true);
                  rangeRef.current = range;
                  event.preventDefault();
                  event.stopPropagation();
                }}
                type="thread"
                title="Search for threads..."
                showCount={false}
              />
            )}
          </Flex>
        </div>
      </Theme>,
      document.body,
    );
  },
);

MentionList.displayName = "MentionList";
